var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.slotsToDelete)?_c('DeleteSlots',{attrs:{"slots":_vm.slotsToDelete,"is-client-request":_vm.isClientRequest},on:{"slotsChanged":_vm.slotsDeleted,"hidden":() => {
        _vm.slotsToDelete = null;
      }}}):_vm._e(),(_vm.slotsToMove)?_c('MoveSlots',{attrs:{"slots":_vm.slotsToMove,"is-client-request":_vm.isClientRequest,"conversation-for-client-action":_vm.conversationForClientAction},on:{"slotsChanged":_vm.slotsMoved,"hidden":() => {
        _vm.slotsToMove = null;
      }}}):_vm._e(),(_vm.loading)?_c('div',[_vm._m(0)]):(_vm.loadingError)?_c('div',{staticClass:"cm-loading-error"},[_vm._v(" "+_vm._s(_vm.$t("calendarModule.errors.loadingError"))+" ")]):_c('div',[(!_vm.isEditSlot)?_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"description":_vm.$t('calendarModule.slots.labels.chosenDateDescription'),"state":!_vm.$v.chosenDate.$error},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("calendarModule.slots.labels.chosenDate"))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,false,3087017427)},[_c('date-picker',{staticClass:"w-100",attrs:{"format":"DD.MM.YYYY","input-class":"form-control pac-target-input"},on:{"change":_vm.chosenDateChanged},model:{value:(_vm.chosenDate),callback:function ($$v) {_vm.chosenDate=$$v},expression:"chosenDate"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(_vm.$t("calendarModule.defaultSlotForm.errors.fromDate")))])],1)],1)],1):_vm._e(),(!_vm.isEditSlot)?_c('b-row',[(_vm.bookedSlots.length > 0 && _vm.showOnlyBookedSlotsButtons && !_vm.showOnlyFreeSlotsButtons)?_c('b-col',[_c('h5',[_vm._v(" "+_vm._s(_vm.$t("calendarModule.massSlotsActions.bookedSlots.heading"))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t( "calendarModule.massSlotsActions.bookedSlots.infoText" ).replace("{amount}", _vm.bookedSlots.length))+" ")]),_c('div',{staticClass:"mb-1"}),_c('div',{staticClass:"mb-1"})]):_vm._e(),(_vm.freeSlots.length > 0 && _vm.showOnlyFreeSlotsButtons && !_vm.showOnlyBookedSlotsButtons)?_c('b-col',[_c('h5',[_vm._v(_vm._s(_vm.$t("calendarModule.massSlotsActions.freeSlots.heading")))]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("calendarModule.massSlotsActions.freeSlots.infoText").replace( "{amount}", _vm.freeSlots.length ))+" ")]),_c('div',{staticClass:"mb-1"},[_c('b-button',{staticClass:"w-100",attrs:{"size":"xs","disabled":_vm.freeSlots.length === 0,"variant":"danger"},on:{"click":() => {
                _vm.slotsToDelete = _vm.freeSlots;
              }}},[_vm._v(" "+_vm._s(_vm.$t("calendarModule.massSlotsActions.freeSlots.delete").replace( "{amount}", _vm.freeSlots.length ))+" ")])],1),_c('div',{staticClass:"mb-1"},[_c('b-button',{staticClass:"w-100",attrs:{"size":"xs","disabled":_vm.freeSlots.length === 0,"variant":"danger"},on:{"click":() => {
                _vm.slotsToMove = _vm.freeSlots;
              }}},[_vm._v(" "+_vm._s(_vm.$t( "calendarModule.massSlotsActions.freeSlots.moveToDate" ).replace("{amount}", _vm.freeSlots.length))+" ")])],1)]):_vm._e()],1):_vm._e(),(_vm.isEditSlot)?_c('b-row',[(_vm.bookedSlots.length > 0)?_c('b-col',[_c('h5',[_vm._v(" "+_vm._s(_vm.$t("calendarModule.massSlotsActions.bookedSlots.heading"))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t( "calendarModule.massSlotsActions.bookedSlots.infoText" ).replace("{amount}", _vm.bookedSlots.length))+" ")])]):_vm._e(),(_vm.freeSlots.length > 0)?_c('b-col',[_c('h5',[_vm._v(_vm._s(_vm.$t("calendarModule.massSlotsActions.freeSlots.heading")))]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("calendarModule.massSlotsActions.freeSlots.infoText").replace( "{amount}", _vm.freeSlots.length ))+" ")]),_c('div',{staticClass:"mb-1"},[_c('b-button',{staticClass:"w-100",attrs:{"size":"xs","disabled":_vm.freeSlots.length === 0,"variant":"danger"},on:{"click":() => {
                _vm.slotsToDelete = _vm.freeSlots;
              }}},[_vm._v(" "+_vm._s(_vm.$t("calendarModule.massSlotsActions.freeSlots.delete").replace( "{amount}", _vm.freeSlots.length ))+" ")])],1),_c('div',{staticClass:"mb-1"},[_c('b-button',{staticClass:"w-100",attrs:{"size":"xs","disabled":_vm.freeSlots.length === 0,"variant":"danger"},on:{"click":() => {
                _vm.slotsToMove = _vm.freeSlots;
              }}},[_vm._v(" "+_vm._s(_vm.$t( "calendarModule.massSlotsActions.freeSlots.moveToDate" ).replace("{amount}", _vm.freeSlots.length))+" ")])],1)]):_vm._e()],1):_vm._e(),_c('b-row',{staticClass:"mt-4"}),(_vm.noList !== true)?_c('div',{staticClass:"mt-4"},[_c('h5',[_vm._v(_vm._s(_vm.$t("calendarModule.massSlotsActions.slotList")))]),(!_vm.isListShown)?_c('div',[_c('b-button',{attrs:{"size":"xs"},on:{"click":() => (_vm.forceShowList = true)}},[_vm._v(" "+_vm._s(_vm.$t("calendarModule.massSlotsActions.loadSlotList"))+" ")])],1):_c('SlotList',{key:_vm.isReloadSlotList,attrs:{"the-slots":_vm.chosenSlots,"with-edit":_vm.isEditSlot,"edit-in-list":_vm.editInList,"is-from-calendar-module-settings":_vm.isFromCalendarModuleSettings},on:{"slotsChanged":_vm.slotsChanged,"freeFilterNull":_vm.setFreeAndBookedSlotsButtons,"freeFilterFalse":_vm.setOnlyBookedSlotsButtons,"freeFilterTrue":_vm.setOnlyFreeSlotsButtons}})],1):_vm._e()],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-between mx-5 mt-3 mb-5"},[_c('div',{staticClass:"spinner-border text-success align-self-center loader-lg"})])
}]

export { render, staticRenderFns }