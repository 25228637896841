<template>
  <div>
    <DeleteSlots
      v-if="slotsToDelete"
      :slots="slotsToDelete"
      :is-client-request="isClientRequest"
      @slotsChanged="slotsDeleted"
      @hidden="
        () => {
          slotsToDelete = null;
        }
      "
    />
    <MoveSlots
      v-if="slotsToMove"
      :slots="slotsToMove"
      :is-client-request="isClientRequest"
      :conversation-for-client-action="conversationForClientAction"
      @slotsChanged="slotsMoved"
      @hidden="
        () => {
          slotsToMove = null;
        }
      "
    />
    <div v-if="loading">
      <div class="d-flex justify-content-between mx-5 mt-3 mb-5">
        <div class="spinner-border text-success align-self-center loader-lg" />
      </div>
    </div>
    <div v-else-if="loadingError" class="cm-loading-error">
      {{ $t("calendarModule.errors.loadingError") }}
    </div>
    <div v-else>
      <b-row v-if="!isEditSlot">
        <b-col>
          <b-form-group
            :description="
              $t('calendarModule.slots.labels.chosenDateDescription')
            "
            :state="!$v.chosenDate.$error"
          >
            <template v-slot:label>
              {{ $t("calendarModule.slots.labels.chosenDate") }}
              <span class="text-danger">*</span>
            </template>
            <date-picker
              class="w-100"
              format="DD.MM.YYYY"
              input-class="form-control pac-target-input"
              v-model="chosenDate"
              @change="chosenDateChanged"
            ></date-picker>
            <b-form-invalid-feedback>{{
              $t("calendarModule.defaultSlotForm.errors.fromDate")
            }}</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="!isEditSlot">
        <b-col v-if="bookedSlots.length > 0 && showOnlyBookedSlotsButtons && !showOnlyFreeSlotsButtons">
          <h5>
            {{ $t("calendarModule.massSlotsActions.bookedSlots.heading") }}
          </h5>
          <p>
            {{
              $t(
                "calendarModule.massSlotsActions.bookedSlots.infoText"
              ).replace("{amount}", bookedSlots.length)
            }}
          </p>
          <div class="mb-1">
            <!-- <b-button
              size="xs"
              class="w-100"
              :disabled="bookedSlots.length === 0"
              variant="danger"
              @click="
                () => {
                  slotsToDelete = bookedSlots;
                }
              "
            >
              {{
                $t(
                  "calendarModule.massSlotsActions.bookedSlots.cancel"
                ).replace("{amount}", bookedSlots.length)
              }}
            </b-button>-->
          </div>
          <div class="mb-1">
           <!-- <b-button
              size="xs"
              class="w-100"
              :disabled="bookedSlots.length === 0"
              variant="danger"
              @click="
                () => {
                  slotsToMove = bookedSlots;
                }
              "
            >
              {{
                $t(
                  "calendarModule.massSlotsActions.bookedSlots.moveToDate"
                ).replace("{amount}", bookedSlots.length)
              }}
            </b-button>-->
          </div>
        </b-col>
        <b-col v-if="freeSlots.length > 0 && showOnlyFreeSlotsButtons && !showOnlyBookedSlotsButtons">
          <h5>{{ $t("calendarModule.massSlotsActions.freeSlots.heading") }}</h5>
          <p>
            {{
              $t("calendarModule.massSlotsActions.freeSlots.infoText").replace(
                "{amount}",
                freeSlots.length
              )
            }}
          </p>
          <div class="mb-1">
            <b-button
              size="xs"
              class="w-100"
              :disabled="freeSlots.length === 0"
              variant="danger"
              @click="
                () => {
                  slotsToDelete = freeSlots;
                }
              "
            >
              {{
                $t("calendarModule.massSlotsActions.freeSlots.delete").replace(
                  "{amount}",
                  freeSlots.length
                )
              }}
            </b-button>
          </div>
          <div class="mb-1">
            <b-button
              size="xs"
              class="w-100"
              :disabled="freeSlots.length === 0"
              variant="danger"
              @click="
                () => {
                  slotsToMove = freeSlots;
                }
              "
            >
              {{
                $t(
                  "calendarModule.massSlotsActions.freeSlots.moveToDate"
                ).replace("{amount}", freeSlots.length)
              }}
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="isEditSlot">
        <b-col v-if="bookedSlots.length > 0">
          <h5>
            {{ $t("calendarModule.massSlotsActions.bookedSlots.heading") }}
          </h5>
          <p>
            {{
              $t(
                "calendarModule.massSlotsActions.bookedSlots.infoText"
              ).replace("{amount}", bookedSlots.length)
            }}
          </p>
          <!-- <div class="mb-1">
             <b-button
               v-if="!isClientRequest"
               size="xs"
               class="w-100"
               :disabled="bookedSlots.length === 0"
               variant="danger"
               @click="
                 () => {
                   slotsToDelete = bookedSlots;
                 }
               "
             >
               {{
                 $t(
                   "calendarModule.massSlotsActions.bookedSlots.cancel"
                 ).replace("{amount}", bookedSlots.length)
               }}
             </b-button>
          </div>
          <div class="mb-1">
            <b-button
              size="xs"
              class="w-100"
              :disabled="bookedSlots.length === 0"
              variant="danger"
              @click="
                () => {
                  slotsToMove = bookedSlots;
                }
              "
            >
              {{
                $t(
                  "calendarModule.massSlotsActions.bookedSlots.moveToDate"
                ).replace("{amount}", bookedSlots.length)
              }}
            </b-button>
          </div>-->
        </b-col>
        <b-col v-if="freeSlots.length > 0">
          <h5>{{ $t("calendarModule.massSlotsActions.freeSlots.heading") }}</h5>
          <p>
            {{
              $t("calendarModule.massSlotsActions.freeSlots.infoText").replace(
                "{amount}",
                freeSlots.length
              )
            }}
          </p>
          <div class="mb-1">
            <b-button
              size="xs"
              class="w-100"
              :disabled="freeSlots.length === 0"
              variant="danger"
              @click="
                () => {
                  slotsToDelete = freeSlots;
                }
              "
            >
              {{
                $t("calendarModule.massSlotsActions.freeSlots.delete").replace(
                  "{amount}",
                  freeSlots.length
                )
              }}
            </b-button>
          </div>
          <div class="mb-1">
            <b-button
              size="xs"
              class="w-100"
              :disabled="freeSlots.length === 0"
              variant="danger"
              @click="
                () => {
                  slotsToMove = freeSlots;
                }
              "
            >
              {{
                $t(
                  "calendarModule.massSlotsActions.freeSlots.moveToDate"
                ).replace("{amount}", freeSlots.length)
              }}
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-4"/>
      <div class="mt-4" v-if="noList !== true">
        <h5>{{ $t("calendarModule.massSlotsActions.slotList") }}</h5>
        <div v-if="!isListShown">
          <b-button size="xs" @click="() => (forceShowList = true)">
            {{ $t("calendarModule.massSlotsActions.loadSlotList") }}
          </b-button>
        </div>
        <SlotList
          v-else
          :the-slots="chosenSlots"
          :with-edit="isEditSlot"
          :edit-in-list="editInList"
          :is-from-calendar-module-settings="isFromCalendarModuleSettings"
          :key="isReloadSlotList"
          @slotsChanged="slotsChanged"
          @freeFilterNull="setFreeAndBookedSlotsButtons"
          @freeFilterFalse="setOnlyBookedSlotsButtons"
          @freeFilterTrue="setOnlyFreeSlotsButtons"
        />
      </div>
    </div>
  </div>
</template>

<script>
import * as cmApi from "../../../store/modules/calendarModule/calendarModule.api";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/de";
import "vue2-datepicker/index.css";
import { mapGetters } from "vuex";
import * as moment from "moment";
import { required } from "vuelidate/lib/validators";

export default {
  name: "MassSlotsActions",
  props: [
    "slots",
    "noList",
    "isEdit",
    "editInList",
    "isFromCalendarModuleSettings",
    "isClientRequest",
    "conversationForClientAction"
  ],
  components: {
    DatePicker,
    MoveSlots: () =>
      import("../../../components/calendarModule/common/MoveSlots"),
    DeleteSlots: () =>
      import("../../../components/calendarModule/common/DeleteSlots"),
    SlotList: () => import("../../../components/calendarModule/common/SlotList")
  },
  data() {
    return {
      showOnlyBookedSlotsButtons: false,
      showOnlyFreeSlotsButtons: false,
      isEditSlot: false,
      isReloadSlotList: false,
      moment: moment,
      allSlots: null,
      chosenSlots: null,
      currentUser: null,
      cmApi,
      loading: false,
      loadingError: false,
      forceShowList: false,
      slotsToMove: null,
      slotsToDelete: null,
      slotsToCancel: null,
      chosenDate: moment().toDate()
    };
  },
  validations: {
    chosenDate: {
      required
    }
  },
  computed: {
    ...mapGetters("users",["getCurrentUser"]),
    bookedSlots() {
      if (this.chosenSlots) {
        return this.chosenSlots.filter(slot => slot.free === false);
      } else {
        return false;
      }
    },
    freeSlots() {
      if (this.chosenSlots) {
        return this.chosenSlots.filter(slot => slot.free === true);
      } else {
        return false;
      }
    },
    isListShown() {
      if (this.chosenSlots) {
        return this.forceShowList === true || this.chosenSlots.length <= 25;
      } else {
        return false;
      }
    }
  },
  methods: {
    setFreeAndBookedSlotsButtons() {
      this.showOnlyBookedSlotsButtons = true;
      this.showOnlyFreeSlotsButtons = true;
    },
    setOnlyBookedSlotsButtons() {
      this.showOnlyFreeSlotsButtons = false;
      this.showOnlyBookedSlotsButtons = true;
    },
    setOnlyFreeSlotsButtons() {
      this.showOnlyBookedSlotsButtons = false;
      this.showOnlyFreeSlotsButtons = true;
    },
    chosenDateChanged() {
      this.chosenSlots = [];
      for (let i = 0; i < this.allSlots.length; i++) {
        if (
          moment(this.chosenDate).format("YYYY-MM-DD") === this.allSlots[i].date
        ) {
          this.chosenSlots.push(this.allSlots[i]);
        }
      }
      this.isReloadSlotList = !this.isReloadSlotList;
    },
    slotsChanged() {
      this.$emit("slotsChanged");
    },
    slotsCanceled() {
      this.slotsToCancel = null;
      this.$emit("slotsChanged");
    },
    slotsDeleted() {
      this.slotsToDelete = null;
      this.$emit("slotsChanged");
    },
    slotsMoved() {
      this.isReloadSlotList = !this.isReloadSlotList;
      this.slotsToMove = null;
      this.$emit("slotsChanged");
    }
  },
  created() {
    if (this.getCurrentUser) {
      this.currentUser = this.getCurrentUser;
    }
    this.isEditSlot = this.isEdit;
    if (!this.slots) {
      this.loading = true;
      this.loadingError = false;
      cmApi.slot
        .getAllTimeSlots(this.currentUser._id)
        .then(res => {
          this.loading = false;
          this.loadingError = false;
          const timeSlots = res.data.data;
          this.allSlots = timeSlots;
          this.chosenDateChanged();
        })
        .catch(err => {
          this.loading = false;
          this.loadingError = true;
        });
    } else {
      this.chosenSlots = this.slots;
    }
  }
};
</script>
